<template>
  <v-container
    fluid
    grid-list-xl
    fill-height>
    <v-layout
      justify-center
      align-center
    >

      <template v-if="loaded">
        <data-table  ref="datatable"
                     :api-end-point="apiurl"
                     :search="search"
                     :sort-order="sortOrder"
                     :table-name="'Notifications'"
                     :isDetail="true"
                     :isExport="true"
                     :pre-selected-filters="filters.selectedFilters"
        >
          <template slot="datatable-actions">
            <div class="action-buttons">
              <button class="btn btn-primary" @click="showNotificationModal">{{ newNotificationButtonText }}</button>
            </div>
          </template>
          <template slot="filters">
            <filters :filters="filters.defaultFilters"></filters>
          </template>
        </data-table>
      </template>

      <!-- New Notification -->
      <new-notification v-if="newNotificationDialog"
                  :visible="newNotificationDialog"
                  v-on:sendNotification="sendNewNotification"
                  v-on:hide="hideNotificationModal"
      >
      </new-notification>

    </v-layout>
  </v-container>
</template>

<script>
import NewNotification from "../components/messaging/NewNotification.vue";
import DataTable from "../components/common/dataTable/DataTable";
import Filters from "../components/common/dataTable/filters/Filters";
export default {
  name:"Notifications",
    components: {Filters, DataTable, NewNotification},
    data() {
        return {
            newNotificationDialog: false,
            newNotificationButtonText: 'New Notification',
            apiurl: 'notifications' ,
            search:{
                placeholder:  '',
                searchAble: false
            },
            sortOrder: [
                {
                    field: 'title',
                    sortField: 'title',
                    direction: 'desc'
                }
            ],
            loaded: false,
            filters: null
        }
    },
    created() {
      this.fetchFilters();
    },
    methods: {
        showNotificationModal() {
            this.newNotificationDialog = true;
        },
        hideNotificationModal() {
            this.newNotificationDialog  = false;
        },
        sendNewNotification(title, notification, filters) {
            let self = this;
            let loader = this.$loading.show();
            this.$http.put(this.url+'send_notification', {
                title: title,
                notification: notification,
                filters: filters.join(',')
            }).then(function (response) {
                self.$events.fire('vuetable:reload');
                loader.hide();
                self.$toastr('success',response.data.message,'');
            }).catch(function (error) {
                loader.hide();
                self.$toastr('error', error.response.data.message,'');
            });
        },
        fetchFilters(){
          this.$http.get(this.url+'filters/notifications').then((res) => {
            if(res.data) {
              this.filters = res.data;
              this.loaded = true;
            } else {
              alert('there is some problem');
            }
          });
        },
    }
}
</script>
